*,
*::before,
*::after {
    box-sizing: border-box;
}

.App {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    gap: 4px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.main-content {
    text-align: center;
    display: flex;
    align-items: stretch;
    flex-grow: 1;
    flex-flow: row nowrap;
    gap: 8px;
    overflow: auto;
    padding-left: 6px;
    padding-right: 6px;
}

@media (max-width: 1000px) {
    .main-content {
        flex-flow: column nowrap;
    }

    .sidebar {
        width: 100% !important;
    }

    .scheduler-wrapper {
        width: 100% !important;
    }

    .calendar-wrapper {
        overflow: auto;
        width: auto;
        height: auto;
    }
}

.header {
    border-bottom: 2px solid grey;
    border-top: none !important;
}

.title-text {
    font-size: 20px !important;
    white-space: nowrap;
}

.banner {
    padding: 6px;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-top: 2px solid grey;
}

.banner-left {
    flex: 1;
    display: flex;
    justify-content: left;
    gap: 6px;
    align-items: center;
}

.banner-center {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 6px;
    align-items: center;
}

.banner-right {
    flex: 1;
    display: flex;
    justify-content: right;
    gap: 6px;
    align-items: center;
}

.banner-text {
    color: var(--colour-text);
    font-size: 14px;
}

body {
    background-color: var(--colour-background);
}

.app-component {
}

.sidebar {
    min-width: 300px;
    width: 300px;
    display: flex;
    flex-flow: column nowrap;
    gap: 5px;
}

.scheduler-wrapper {
    min-width: 300px;
    width: 300px;
    display: flex;
    flex-flow: column nowrap;
    gap: 5px;
}

.calendar-wrapper {
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;
}

html, body {
    overflow: hidden
}
