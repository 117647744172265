.calendar-preview {
    display: flex;
    flex-flow: column nowrap;
    background: linear-gradient(to bottom, var(--colour-primary) 200px, var(--colour-grid_border) 25px);
    margin: 2px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    border: 3px solid var(--colour-background);
    transition: border 0.3s;
}

.calendar-preview:hover {
    border: 3px solid var(--colour-highlight);
}

.calendar-preview-slots {
    padding-bottom: 1em;
    color: black;
    display: grid;
    flex-grow: 1;
    grid-template-rows:
        [time-0800] 1fr
        [time-0830] 1fr
        [time-0900] 1fr
        [time-0930] 1fr
        [time-1000] 1fr
        [time-1030] 1fr
        [time-1100] 1fr
        [time-1130] 1fr
        [time-1200] 1fr
        [time-1230] 1fr
        [time-1300] 1fr
        [time-1330] 1fr
        [time-1400] 1fr
        [time-1430] 1fr
        [time-1500] 1fr
        [time-1530] 1fr
        [time-1600] 1fr
        [time-1630] 1fr
        [time-1700] 1fr
        [time-1730] 1fr
        [time-1800] 1fr
        [time-1830] 1fr
        [time-1900] 1fr
        [time-1930] 1fr
        [time-2000] 1fr
        [time-2030] 1fr
        [time-2100] 1fr
        [time-2130] 1fr
        [time-2200] 1fr;
    grid-template-columns:
        [mon] 1fr
        [tue] 1fr
        [wed] 1fr
        [thu] 1fr
        [fri] 1fr;
    height: 200px;
}

.calendar-preview-footer {
    height: 25px;
}

.scheduler {
    overflow: scroll;
    color: var(--colour-text);
}

.scheduler::-webkit-scrollbar {
    display: none;
}

.preview-time-slot {
    width: 100%;
}
