.pull-right {
    float: right;
}

.course-header-action-bar {
    display: flex;
    flex-flow: row nowrap;
    gap: 6px;
}

.action-bar-text {
    flex-grow: 1;
}

.course-list {
    background-color: var(--colour-background);
    flex-grow: 1;
    overflow: hidden;
    color: var(--colour-text);
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
}

.course-list-body {
    overflow: auto;
}

.course-list-body::-webkit-scrollbar {
    display: none;
}

.course-list-stage {
    flex-grow: 3;
    overflow: auto;
}

.course-header {
    display: flex;
    flex-flow: row nowrap;
}

.course-header-colour {
    width: 7px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.course-header-default-background {
    background-color: var(--colour-primary);
    transition: background-color 0.15s ease-out;
}

.course-header-default-background:hover {
    background-color: var(--colour-interactable);
}

.course-header-text {
    background-color: var(--colour-primary);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 4px;
    padding-right: 12px;
    line-height: 30px;
    margin-top: 4px;
    margin-bottom: 4px;
    flex-grow: 1;
    transition: background-color 0.15s ease-out;
    cursor: pointer;
}

.course-header-text:hover, .course-header-text-expanded {
    background-color: var(--colour-interactable);
}

.course-header-text-expanded:hover {
    background-color: var(--colour-primary);
}

.course-header-unavailable {
    color: red;
}

.child-course-item {
    color: black;
    background-color: #d0d0d0;
    margin: 8px;
    text-align: left;
    padding: 5px;
    border-radius: 5px;
}

.child-course-header-item {
    color: var(--colour-text);
    background-color: var(--colour-primary);
    font-weight: bold;
    font-size: large;
    margin-top: 5px;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
}

.course-list-header-item {
    color: var(--colour-text);
    background-color: #b3282d;
    font-weight: bold;
    font-size: large;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
}

.course-item-block {
    text-align: left;
    display: inline-block;
    margin-left: 20px;
}

.text-muted {
    opacity: 50%;
}
